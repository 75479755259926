import React, { useState } from "react";

import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
} from "reactstrap";

import brand from "../../assets/img/brand.png";

import { adminRoutes, printerRoutes } from "../../routes";
import { useLocation, useNavigate } from "react-router-dom";
import {
  faSignOutAlt,
  faHandshake,
  faUsers,
  faThLarge,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../providers/authProvider";
import { authApi } from "../../services/authServices";
import { useProgramStatements } from "../../providers/programStatementsProvider";
import ProgramStatementsModal from "./programDetails/ProgramStatementsModal";
import ProgramCloseDocumentsModal from "./programDetails/ProgramCloseDocumentsModal";
import { useProgramCloseDocuments } from "../../providers/programCloseDocumentsProvider";
import { utils } from "../../utils/utils";
import { CUSTOMER_SUPPORT, PRINTER, SPONSOR } from "../../utils/roles";

const STATEMENTS_MODAL_DEFAULT_MODE = 1;
const CLOSE_DOCUMENTS_MODAL_DEFAULT_MODE = 1;

const NavbarMenu = ({ user }) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [, setAuthContext] = useAuth();

  const isCS = utils.userHasRole(user, CUSTOMER_SUPPORT);
  const isSponsor = utils.userHasRole(user, SPONSOR);
  const isPrinter = utils.userHasRole(user, PRINTER);

  const logout = () => {
    if (isSponsor) {
      authApi.logout(SPONSOR);
    } else if (isCS) {
      authApi.logout("customer-support");
    } else if (isPrinter) {
      authApi.logout("printer");
    } else {
      authApi.logout("admin");
    }
    setAuthContext({ currentUser: null, advisements: null });
    navigate("/auth/sign-in");
  };

  return (
    <Dropdown
      direction="left"
      className="d-flex mr-2"
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      inNavbar={true}
    >
      <DropdownToggle
        color="white"
        tag="div"
        style={{ width: "20px", height: "20px", marginTop: "10px" }}
      >
        <i className="hamburger align-self-center" />
      </DropdownToggle>
      <DropdownMenu>
        {!isSponsor && !isPrinter && (
          <>
            {!isCS && (
              <>
                <DropdownItem onClick={() => navigate("/back/categories")}>
                  <FontAwesomeIcon className="mr-2" icon={faThLarge} />
                  Categories
                </DropdownItem>
                <DropdownItem onClick={() => navigate("/back/sponsors")}>
                  <FontAwesomeIcon className="mr-2" icon={faHandshake} />
                  Sponsors
                </DropdownItem>
                <DropdownItem onClick={() => navigate("/back/users")}>
                  <FontAwesomeIcon className="mr-2" icon={faUsers} />
                  Users
                </DropdownItem>
              </>
            )}
            <DropdownItem onClick={() => navigate("/back/settings")}>
              <FontAwesomeIcon className="mr-2" icon={faCog} />
              Settings
            </DropdownItem>
            <DropdownItem onClick={() => navigate("/back/marketing-banners")}>
              <FontAwesomeIcon className="mr-2" icon={faCog} />
              Banner
            </DropdownItem>
          </>
        )}
        {isSponsor ? (
          <DropdownItem onClick={() => navigate("/back/settings")}>
            <FontAwesomeIcon className="mr-2" icon={faCog} />
            Settings
          </DropdownItem>
        ) : null}
        <DropdownItem onClick={logout}>
          <FontAwesomeIcon className="mr-2" icon={faSignOutAlt} />
          Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const NavbarElements = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState({});

  const isCS = utils.userHasRole(user, CUSTOMER_SUPPORT);
  const isSponsor = utils.userHasRole(user, SPONSOR);
  const isPrinter = utils.userHasRole(user, PRINTER);

  return (
    <Nav className="mr-auto" navbar>
      <div className="sidebar-brand d-flex align-items-center p-0">
        <img src={brand} className="rounded p-0 col-12" alt={"Vulcan Fund"} />
      </div>
      {(!isPrinter ? adminRoutes(isCS, isSponsor) : printerRoutes)
        .filter((r) => r.navbar)
        .map((route, i) =>
          route.children ? (
            <NavItem key={i} className="d-flex align-items-center">
              <NavLink
                className={
                  location.pathname.indexOf(route.path) > -1 ? "active" : ""
                }
                href="#"
              >
                <Dropdown
                  direction="down"
                  isOpen={isOpen[i]}
                  toggle={() => setIsOpen({ [i]: false })}
                  onMouseEnter={() => setIsOpen({ [i]: true })}
                  onMouseLeave={() => setIsOpen({ [i]: false })}
                  inNavbar={true}
                >
                  <DropdownToggle color="white" tag="span">
                    {route.name}
                  </DropdownToggle>
                  <DropdownMenu>
                    {route.children.map((child, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() => navigate(child.url)}
                      >
                        {child.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </NavLink>
            </NavItem>
          ) : (
            <NavItem key={i} className="d-flex align-items-center">
              <NavLink
                className={
                  location.pathname.indexOf(route.path) > -1 ? "active" : ""
                }
                href="#"
                onClick={() => navigate(route.path)}
              >
                {route.name}
              </NavLink>
            </NavItem>
          )
        )}
    </Nav>
  );
};

const NavbarComponent = () => {
  const [programStatements, setProgramStatements] = useProgramStatements();
  const [programCloseDocuments, setProgramCloseDocuments] =
    useProgramCloseDocuments();
  const [authContext] = useAuth();

  const user = authContext.currentUser;

  return (
    <Navbar expand className="navbar-theme">
      {
        <React.Fragment>
          <NavbarElements user={user} />
          <NavbarMenu user={user} />
        </React.Fragment>
      }
      {programStatements.isActive ? (
        <ProgramStatementsModal
          onClose={() =>
            setProgramStatements({
              isActive: false,
              mode: STATEMENTS_MODAL_DEFAULT_MODE,
              previewFile: null,
              quarter: "",
              letter: "",
              program: null,
              total: 0,
              remaining: 0,
              statements: [],
            })
          }
        />
      ) : null}
      {programCloseDocuments.isActive ? (
        <ProgramCloseDocumentsModal
          onClose={() =>
            setProgramCloseDocuments({
              isActive: false,
              mode: CLOSE_DOCUMENTS_MODAL_DEFAULT_MODE,
              previewFile: null,
              program: null,
              total: 0,
              remaining: 0,
              closeDocuments: [],
            })
          }
        />
      ) : null}
    </Navbar>
  );
};

export default NavbarComponent;
