import React, { useState } from "react";
import Header from "../../components/Header";
import { Container, Row, Button } from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InformationModal from "../../components/InformationModal";
import ConfirmationModal from "../../components/ConfirmationModal";

const MarketingBanner = () => {
  const initModalData = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [informationModal, setInformationModal] = useState(initModalData);
  const [confirmationModal, setConfirmationModal] = useState(initModalData);

  return (
    <div>
      <Container fluid>
        <Header>
          <Row className="m-0 justify-content-end">
            <Button className="rounded" color="primary" onClick={() => {}}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Create New Banner</span>
            </Button>
          </Row>
        </Header>
      </Container>
      {informationModal.isOpen ? (
        <InformationModal {...informationModal} />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </div>
  );
};

export default MarketingBanner;
